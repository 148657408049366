import Header from '../../Component/Header/Header';
import Footer from '../../Component/Footer/Footer';

const Acces = () => {
    return (
        <div>
        <header>
          <Header />
        </header>
        <main>
          <h1>Accessibilité</h1>
        </main>
        <footer>
          <Footer />
        </footer>
      </div>
    )
}

export default Acces;